import React, { useState, useEffect, useCallback } from 'react';
import {
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  RadioGroup,
  Radio,
  MenuItem,
  Typography,
  Container,
  Box,
  IconButton,
  Select,
  InputLabel,
  FormControl,
  Snackbar,
  Alert
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AsyncSelect from 'react-select/async';
import api from '../utils/authUtils';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function AddPrayerPage({ fixedCircleId, onPrayerAdded }) {
  const [content, setContent] = useState('');
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [category, setCategory] = useState('General');
  const [locationType, setLocationType] = useState('auto');
  const [manualLocation, setManualLocation] = useState(null);
  const [prayerCircles, setPrayerCircles] = useState([]);
  const [selectedCircle, setSelectedCircle] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const navigate = useNavigate();

  useEffect(() => {
    if (fixedCircleId) {
      setSelectedCircle(fixedCircleId);
    } else {
      const fetchCircles = async () => {
        try {
          const response = await api.get('/circles/user');
          setPrayerCircles(response.data || []);
        } catch (error) {
          console.error('Error fetching prayer circles:', error);
        }
      };
      fetchCircles();
    }
  }, [fixedCircleId]);

  const loadOptions = async (inputValue) => {
    if (!inputValue) {
      return [];
    }
    try {
      const response = await axios.get(
        `https://nominatim.openstreetmap.org/search?format=json&q=${inputValue}`
      );
      return response.data.map((location) => ({
        label: location.display_name,
        value: { lat: location.lat, lon: location.lon },
      }));
    } catch (error) {
      console.error('Error loading options:', error);
      return [];
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (!content) {
        setSnackbarMessage('Prayer content is required.');
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
        return;
      }

      try {
        let latitude, longitude;

        if (locationType === 'auto') {
          const position = await new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject);
          });
          latitude = position.coords.latitude;
          longitude = position.coords.longitude;
        } else if (manualLocation) {
          latitude = manualLocation.value.lat;
          longitude = manualLocation.value.lon;
        } else {
          throw new Error('Location is required');
        }

        const prayerData = {
          content,
          is_anonymous: isAnonymous,
          category,
          latitude,
          longitude,
          circle_id: fixedCircleId || selectedCircle || null,
        };

        const response = await api.post('/prayers', prayerData);

        setSnackbarMessage('Prayer submitted successfully!');
        setSnackbarSeverity('success');
        setOpenSnackbar(true);

        if (onPrayerAdded) {
          onPrayerAdded(response.data);
        } else {
          navigate('/dashboard');
        }
      } catch (err) {
        setSnackbarMessage('Failed to submit prayer.');
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
        console.error('Error submitting prayer:', err);
      }
    },
    [content, isAnonymous, category, locationType, manualLocation, selectedCircle, fixedCircleId, navigate, onPrayerAdded]
  );

  return (
    <Container sx={{ mt: 4 }}>
      {!fixedCircleId && (
        <Box display="flex" alignItems="center" mb={2}>
          <IconButton onClick={() => navigate('/dashboard')}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h5" component="h1" ml={1}>
            Add New Prayer
          </Typography>
        </Box>
      )}
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 2 }}
      >
        <TextField
          placeholder="Enter your prayer here"
          value={content}
          onChange={(e) => setContent(e.target.value)}
          multiline
          rows={4}
          required
          variant="outlined"
        />

        <TextField
          select
          label="Category"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          variant="outlined"
        >
          <MenuItem value="General">General</MenuItem>
          <MenuItem value="Health">Health</MenuItem>
          <MenuItem value="Family">Family</MenuItem>
          <MenuItem value="Other">Other</MenuItem>
        </TextField>

        {!fixedCircleId && (
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="prayer-circle-label">Prayer Circle</InputLabel>
            <Select
              labelId="prayer-circle-label"
              value={selectedCircle}
              onChange={(e) => setSelectedCircle(e.target.value)}
              label="Prayer Circle"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {prayerCircles.map((circle) => (
                <MenuItem key={circle.id} value={circle.id}>
                  {circle.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {selectedCircle && (
          <Typography variant="caption" color="textSecondary">
            The prayer will only be visible to members of the selected prayer circle.
          </Typography>
        )}

        <FormControlLabel
          control={
            <Checkbox checked={isAnonymous} onChange={(e) => setIsAnonymous(e.target.checked)} />
          }
          label="Post as Anonymous"
        />

        <RadioGroup
          aria-label="location-type"
          name="location-type"
          value={locationType}
          onChange={(e) => setLocationType(e.target.value)}
        >
          <FormControlLabel value="auto" control={<Radio />} label="Use my current location" />
          <FormControlLabel value="manual" control={<Radio />} label="Choose a location" />
        </RadioGroup>

        {locationType === 'manual' && (
          <AsyncSelect
            cacheOptions
            loadOptions={loadOptions}
            defaultOptions
            placeholder="Search for location"
            onChange={(selectedOption) => setManualLocation(selectedOption)}
          />
        )}

        <Button type="submit" variant="contained" color="primary">
          Submit Prayer
        </Button>
      </Box>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default AddPrayerPage;