import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Typography, Button, Card, CardContent, Snackbar, Alert } from '@mui/material';
import api from '../utils/authUtils';  // Assicurati che questo contenga la logica per gestire le chiamate API

const ManageRequests = () => {
  const { circleId } = useParams();
  const [requests, setRequests] = useState([]);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });

  useEffect(() => {
    const fetchRequests = async () => {
      try {
        const response = await api.get(`/circles/${circleId}/requests`);
        setRequests(response.data);
      } catch (error) {
        console.error('Error fetching requests:', error);
        setSnackbar({ open: true, message: 'Failed to fetch requests', severity: 'error' });
      }
    };

    fetchRequests();
  }, [circleId]);

  const handleApprove = async (userId) => {
    try {
      const response = await api.put(`/circles/${circleId}/approve/${userId}`);
      setSnackbar({ open: true, message: response.data.message, severity: 'success' });
      // Rimuovi la richiesta approvata dall'elenco
      setRequests(requests.filter(request => request.user_id !== userId));
    } catch (error) {
      console.error('Error approving request:', error);
      setSnackbar({ open: true, message: 'Failed to approve request', severity: 'error' });
    }
  };

  const handleDeny = async (userId) => {
    try {
      const response = await api.delete(`/circles/${circleId}/deny/${userId}`);
      setSnackbar({ open: true, message: response.data.message, severity: 'success' });
      // Rimuovi la richiesta rifiutata dall'elenco
      setRequests(requests.filter(request => request.user_id !== userId));
    } catch (error) {
      console.error('Error denying request:', error);
      setSnackbar({ open: true, message: 'Failed to deny request', severity: 'error' });
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Pending Requests
      </Typography>

      {requests.length === 0 ? (
        <Typography variant="body1">No pending requests.</Typography>
      ) : (
        requests.map((request) => (
          <Card key={request.user_id} sx={{ mb: 2 }}>
            <CardContent>
              <Typography variant="h6">{request.username}</Typography>
              <Typography variant="body2" color="textSecondary">{request.email}</Typography>
              <Button
                variant="contained"
                color="primary"
                sx={{ mt: 2, mr: 1 }}
                onClick={() => handleApprove(request.user_id)}
              >
                Approve
              </Button>
              <Button
                variant="contained"
                color="secondary"
                sx={{ mt: 2 }}
                onClick={() => handleDeny(request.user_id)}
              >
                Deny
              </Button>
            </CardContent>
          </Card>
        ))
      )}

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ManageRequests;
