import React, { useState, useEffect } from 'react';
import { Grid, ListItemText, IconButton, Typography, Container, Box, Card, CardContent, Divider } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import api from '../utils/authUtils';
import { useNavigate } from 'react-router-dom';

function CollectedPrayersPage() {
  const [collectedPrayers, setCollectedPrayers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCollectedPrayers = async () => {
      try {
        const response = await api.get('/prayers/collected');
        setCollectedPrayers(response.data.collected_prayers || []);
      } catch (error) {
        console.error('Error fetching collected prayers:', error);
      }
    };

    fetchCollectedPrayers();
  }, []);

  return (
    <Container sx={{ mt: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {/* Header */}
      <Box display="flex" alignItems="center" mb={2} sx={{ width: '100%' }}>
        <IconButton onClick={() => navigate('/dashboard')}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h5" component="h1" ml={1} sx={{ flexGrow: 1, textAlign: 'center' }}>
          Your Collected Prayers
        </Typography>
      </Box>

      {/* Lista delle preghiere raccolte */}
      <Grid container spacing={3} sx={{ width: '100%', maxWidth: 1200 }}>
        {collectedPrayers.map((prayer) => (
          <Grid item xs={12} sm={6} md={4} key={prayer.id}>
            <Card sx={{ boxShadow: 4, borderRadius: 2 }}>
              <CardContent>
                <ListItemText
                  primary={
                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#333' }}>
                      {prayer.content}
                    </Typography>
                  }
                  secondary={
                    <>
                      <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                        Category: <span style={{ fontWeight: 'bold', color: '#555' }}>{prayer.category}</span>
                      </Typography>
                      <Typography variant="body2" color="textSecondary" sx={{ mt: 0.5 }}>
                        Author: <span style={{ fontWeight: 'bold', color: '#555' }}>{prayer.author?.username || 'Anonymous'}</span>
                      </Typography>
                      <Typography variant="body2" color="textSecondary" sx={{ mt: 0.5 }}>
                        Collected on: <span style={{ fontWeight: 'bold', color: '#555' }}>{new Date(prayer.collected_at).toLocaleString()}</span>
                      </Typography>
                    </>
                  }
                />
              </CardContent>
              <Divider />
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default CollectedPrayersPage;
