import React, { useState, useEffect, useCallback } from 'react';
import { 
  Container, 
  Typography, 
  Card, 
  CardContent, 
  Avatar, 
  Box, 
  Chip, 
  Grid, 
  Button,
  TextField,
  Snackbar,
  CircularProgress,
  Alert
} from '@mui/material';
import { Person as PersonIcon, Add as AddIcon } from '@mui/icons-material';
import { useParams, useNavigate } from 'react-router-dom';
import api from '../utils/authUtils';
import AddPrayerPage from './AddPrayerPage'; // Importa il componente AddPrayerPage

const CirclePrayers = () => {
  const { circleId } = useParams();
  const navigate = useNavigate();
  const [circleData, setCircleData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showAddPrayer, setShowAddPrayer] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });

  const fetchCirclePrayers = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get(`/circles/${circleId}/prayers`);
      console.log('API Response:', response.data);  // For debugging
      setCircleData(response.data);
      setError(null);
    } catch (err) {
      console.error('Error fetching prayers:', err);
      setError('Failed to fetch prayers. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [circleId]);

  useEffect(() => {
    fetchCirclePrayers();
  }, [fetchCirclePrayers]);

  const handlePrayerAdded = useCallback(() => {
    setShowAddPrayer(false);
    fetchCirclePrayers();
    setSnackbar({ open: true, message: 'Prayer added successfully', severity: 'success' });
  }, [fetchCirclePrayers]);

  if (loading) return (
    <Container maxWidth="md">
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
        <CircularProgress />
      </Box>
    </Container>
  );

  if (error) return (
    <Container maxWidth="md">
      <Box my={4}>
        <Alert severity="error">{error}</Alert>
      </Box>
    </Container>
  );

  if (!circleData || !circleData.circle_info) {
    return (
      <Container maxWidth="md">
        <Box my={4}>
          <Alert severity="warning">No data available for this circle. (circleId: {circleId})</Alert>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Typography variant="h4" gutterBottom>
          {circleData.circle_info.name}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary" gutterBottom>
          {circleData.circle_info.description}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Members: {circleData.circle_info.member_count}
        </Typography>
        <Button 
          variant="contained" 
          color="primary" 
          onClick={() => setShowAddPrayer(!showAddPrayer)}
          sx={{ mt: 2 }}
        >
          {showAddPrayer ? 'Cancel' : 'Add Prayer'}
        </Button>
      </Box>

      {showAddPrayer && (
        <AddPrayerPage circleId={circleId} onPrayerAdded={handlePrayerAdded} />
      )}

      <Grid container spacing={3}>
        {circleData.prayers.map((prayer) => (
          <Grid item xs={12} key={prayer.id}>
            <Card elevation={3}>
              <CardContent>
                <Box display="flex" alignItems="center" mb={2}>
                  {prayer.is_anonymous ? (
                    <Avatar>
                      <PersonIcon />
                    </Avatar>
                  ) : (
                    <Avatar alt={prayer.author?.full_name} src={`/path/to/avatar/${prayer.author?.id}.jpg`} />
                  )}
                  <Box ml={2}>
                    <Typography variant="subtitle1">
                      {prayer.is_anonymous ? 'Anonymous' : prayer.author?.full_name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {prayer.time_ago}
                    </Typography>
                  </Box>
                </Box>
                <Typography variant="body1" paragraph>
                  {prayer.content}
                </Typography>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Chip 
                    label={prayer.category || 'General'} 
                    size="small" 
                    color="primary" 
                    variant="outlined" 
                  />
                  <Typography variant="caption" color="textSecondary">
                    {new Date(prayer.created_at).toLocaleDateString()}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default CirclePrayers;