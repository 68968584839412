import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; 
import { Container, Typography, Button, Card, CardContent, Snackbar, Alert } from '@mui/material';
import api from '../utils/authUtils';  
import { useAuth } from '../hooks/useAuth';  // Assicurati che questo hook gestisca l'autenticazione

const CirclesList = () => {
  const [circles, setCircles] = useState([]);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const { user } = useAuth();  // Ottieni il contesto dell'utente autenticato
  const navigate = useNavigate(); 

  useEffect(() => {
    const fetchCircles = async () => {
      try {
        const response = await api.get('/circles');
        setCircles(response.data);
      } catch (error) {
        console.error('Error fetching circles:', error);
        setSnackbar({ open: true, message: 'Failed to fetch circles', severity: 'error' });
      }
    };

    fetchCircles();
  }, []);

  const handleJoinCircle = async (circleId) => {
    try {
      const response = await api.post(`/circles/${circleId}/join`);
      setSnackbar({ open: true, message: response.data.message, severity: 'success' });
      // Aggiorna lo stato del cerchio a "Pending" dopo che l'utente ha fatto richiesta
      const updatedCircles = circles.map(circle => 
        circle.id === circleId ? { ...circle, status: 'Pending' } : circle
      );
      setCircles(updatedCircles);
    } catch (error) {
      console.error('Error joining circle:', error);
      setSnackbar({ open: true, message: 'Failed to join circle', severity: 'error' });
    }
  };

  const handleAddCircle = () => {
    navigate('/add-circle'); 
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Prayer Circles
      </Typography>

      {/* Pulsante per aggiungere una nuova cerchia */}
      <Button
        variant="contained"
        color="secondary"
        sx={{ mb: 4 }}
        onClick={handleAddCircle}
      >
        Add New Circle
      </Button>

      {circles.length === 0 ? (
        <Typography variant="body1">No circles available.</Typography>
      ) : (
        circles.map((circle) => (
          <Card key={circle.id} sx={{ mb: 2 }}>
            <CardContent>
              <Typography variant="h6">{circle.name}</Typography>
              <Typography variant="body2" color="textSecondary">{circle.description}</Typography>

              {/* Se l'utente è il creatore della cerchia, non mostrare il pulsante "Join" */}
              {circle.created_by !== user.id && (
                <>
                  {/* Mostra lo stato dell'iscrizione dell'utente */}
                  {circle.status === 'Approved' ? (
                    <Typography variant="body2" color="green">You are a member of this circle</Typography>
                  ) : circle.status === 'Pending' ? (
                    <Typography variant="body2" color="orange">Your request is pending approval</Typography>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ mt: 2 }}
                      onClick={() => handleJoinCircle(circle.id)}
                    >
                      {circle.requires_approval ? 'Request to Join' : 'Join Circle'}
                    </Button>
                  )}
                </>
              )}

              {/* Mostra il pulsante per gestire le richieste solo se l'utente è il creatore della cerchia */}
              {circle.created_by === user.id && (
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{ mt: 2, ml: 2 }}
                  onClick={() => navigate(`/circles/${circle.id}/requests`)}
                >
                  Manage Requests
                </Button>
              )}
              
              {/* Aggiungi il pulsante per visualizzare le preghiere se l'utente è un membro */}
              {circle.status === 'Approved' && (
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ mt: 2, ml: 2 }}
                  onClick={() => navigate(`/circles/${circle.id}/prayers`)}
                >
                  View Prayers
                </Button>
              )}
            </CardContent>
          </Card>
        ))
      )}

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default CirclesList;
