import React, { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  Grid,
  Paper,
  Chip,
  Avatar,
  Button,
  TextField,
  CircularProgress,
  Snackbar,
  Alert,
  Container,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import AsyncSelect from 'react-select/async';
import axios from 'axios';
import { useAuth } from '../hooks/useAuth';
import api from '../utils/authUtils';

// Styled Components
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  marginTop: theme.spacing(3),
  boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
  borderRadius: theme.shape.borderRadius,
  textAlign: 'center',
  position: 'relative',
  overflow: 'hidden',
}));

const CircleChip = styled(Chip)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(2),
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  fontSize: '0.75rem',
  height: '24px',
  '& .MuiChip-label': {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

const CircleDescription = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '0.875rem',
  marginTop: theme.spacing(1),
}));

const CircleAvatar = styled(Avatar)(({ theme }) => ({
  width: 64,
  height: 64,
  marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  padding: theme.spacing(1, 4),
  marginTop: theme.spacing(2),
  transition: 'background-color 0.3s ease',
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
}));

function UserProfile() {
  const { user } = useAuth();
  const [profile, setProfile] = useState(null);
  const [circles, setCircles] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    fetchProfile();
    fetchCircles();
  }, []);

  const fetchProfile = async () => {
    try {
      const response = await api.get('/profile');
      setProfile(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching profile:', error);
      setIsLoading(false);
    }
  };

  const fetchCircles = async () => {
    try {
      const response = await api.get('/circles/user');
      setCircles(response.data);
    } catch (error) {
      console.error('Error fetching circles:', error);
    }
  };

  const handleInputChange = (name, value) => {
    setProfile({ ...profile, [name]: value });
  };

  // Funzione per cercare la posizione tramite API
  const loadLocationOptions = async (inputValue) => {
    if (!inputValue) return [];
    try {
      const response = await axios.get(
        `https://nominatim.openstreetmap.org/search?format=json&q=${inputValue}`
      );
      return response.data.map((location) => ({
        label: location.display_name,
        value: location.display_name,
      }));
    } catch (error) {
      console.error('Error loading location options:', error);
      return [];
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await api.put('/profile', profile);
      setIsEditing(false);
      setSuccessMessage('Profile updated successfully!');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error updating profile:', error);
      setErrorMessage('Failed to update profile.');
      setSnackbarOpen(true);
    }
  };

  const closeSnackbar = () => {
    setSnackbarOpen(false);
    setErrorMessage('');
    setSuccessMessage('');
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  if (!profile) {
    return <Alert severity="error">Unable to load profile data.</Alert>;
  }

  return (
    <Container maxWidth="md">
      <StyledPaper>
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} sm={4} sx={{ textAlign: 'center' }}>
              <Avatar 
                src={profile.profile_picture_url || ''} 
                alt="Profile Picture"
                sx={{ width: 150, height: 150 }}
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <Typography variant="h4" gutterBottom>
                {profile.full_name || 'User Profile'}
              </Typography>
              {isEditing ? (
                <form onSubmit={handleSubmit}>
                  <TextField
                    fullWidth
                    margin="normal"
                    name="first_name"
                    label="First Name"
                    value={profile.first_name || ''}
                    onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                  />
                  <TextField
                    fullWidth
                    margin="normal"
                    name="last_name"
                    label="Last Name"
                    value={profile.last_name || ''}
                    onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                  />
                  <TextField
                    fullWidth
                    margin="normal"
                    name="bio"
                    label="Bio"
                    multiline
                    rows={4}
                    value={profile.bio || ''}
                    onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                  />
                  {/* Aggiungi il campo di ricerca per la location */}
                  <div style={{ marginBottom: '20px' }}>
                    <AsyncSelect
                      cacheOptions
                      loadOptions={loadLocationOptions}
                      defaultOptions
                      placeholder="Search for location"
                      onChange={(selectedOption) => handleInputChange('location', selectedOption.value)}
                    />
                  </div>
                  <Box sx={{ mt: 2 }}>
                    <StyledButton type="submit" variant="contained" color="primary">
                      Save Changes
                    </StyledButton>
                    <Button onClick={() => setIsEditing(false)} sx={{ ml: 2 }}>
                      Cancel
                    </Button>
                  </Box>
                </form>
              ) : (
                <>
                  <Typography variant="body1" gutterBottom>
                    <strong>Email:</strong> {profile.email}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    <strong>Username:</strong> {profile.username}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    <strong>Bio:</strong> {profile.bio || 'No bio provided'}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    <strong>Location:</strong> {profile.location || 'Not specified'}
                  </Typography>
                  <StyledButton 
                    onClick={() => setIsEditing(true)} 
                    variant="contained" 
                    color="primary"
                  >
                    Edit Profile
                  </StyledButton>
                </>
              )}
            </Grid>
          </Grid>
        </Box>
      </StyledPaper>

      {/* Mostra le cerchie a cui appartiene */}
      <Box sx={{ mt: 4 }}>
        <Typography variant="h5" gutterBottom>My Prayer Circles</Typography>
        <Grid container spacing={4}>
          {circles.map((circle) => (
            <Grid item xs={12} sm={6} md={4} key={circle.id}>
              <StyledPaper>
                {circle.is_admin && <CircleChip label="Admin" />}
                <CircleAvatar>
                  <Typography variant="h6">{circle.name.charAt(0)}</Typography>
                </CircleAvatar>
                <Typography variant="h6">{circle.name}</Typography>
                <CircleDescription>
                  {circle.description ? circle.description : 'No description available'}
                </CircleDescription>
                <Typography variant="body2">
                  Members can pray together and share their intentions.
                </Typography>
              </StyledPaper>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Snackbar per feedback */}
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={closeSnackbar}>
        <Alert onClose={closeSnackbar} severity={errorMessage ? 'error' : 'success'}>
          {errorMessage || successMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default UserProfile;
