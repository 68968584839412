import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  CircularProgress,
  Fab,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Snackbar,
  Alert,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import PrayerIcon from '@mui/icons-material/AccessibilityNew';
import CollectionIcon from '@mui/icons-material/LibraryBooks';
import MenuIcon from '@mui/icons-material/Menu';
import { MapContainer, TileLayer, Marker, Popup, ZoomControl } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import MarkerClusterGroup from 'react-leaflet-cluster';
import api from '../utils/authUtils';
import { useAuth } from '../hooks/useAuth';
import { useNavigate } from 'react-router-dom';

// Definisci StyledPopupContent
function StyledPopupContent({ title, subtitle, caption, children }) {
  return (
    <Box
      sx={{
        p: 1.5,
        bgcolor: '#fff',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        borderRadius: '10px',
        maxWidth: '280px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        border: '1px solid rgba(33, 102, 205, 0.2)',
      }}
    >
      <Typography
        variant="subtitle1"
        sx={{
          fontWeight: 'bold',
          color: '#2166cd',
          mb: 0.1,
          fontFamily: 'Roboto, sans-serif',
        }}
      >
        {title}
      </Typography>

      <Typography
        variant="body2"
        sx={{
          color: '#555',
          fontStyle: 'italic',
          fontFamily: 'Georgia, serif',
          mb: 0.1,
          lineHeight: 1,
        }}
      >
        {subtitle}
      </Typography>

      <Typography
        variant="caption"
        sx={{
          color: '#777',
          fontSize: '12px',
          mb: 1,
        }}
      >
        {caption}
      </Typography>

      {children}
    </Box>
  );
}

function Dashboard() {
  const [uncollectedPrayers, setUncollectedPrayers] = useState([]);
  const [userData, setUserData] = useState(null);
  const [mapCenter, setMapCenter] = useState([51.505, -0.09]);
  const [mapZoom, setMapZoom] = useState(7);
  const [isLoading, setIsLoading] = useState(true);
  const { logout } = useAuth();
  const [dataFetched, setDataFetched] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [collectingPrayerId, setCollectingPrayerId] = useState(null);
  const [collectMessage, setCollectMessage] = useState('');
  const [collectDialogOpen, setCollectDialogOpen] = useState(false);
  const [wantsToLeaveMessage, setWantsToLeaveMessage] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });

  const mapRef = useRef(null);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const prayerIcon = new L.Icon({
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    shadowSize: [41, 41],
  });

  const createClusterCustomIcon = function (cluster) {
    const count = cluster.getChildCount();
    const size = Math.min(70, 40 + Math.log10(count) * 15);

    return L.divIcon({
      html: `<div class="cluster-icon">${count}</div>`,
      className: 'custom-marker-cluster',
      iconSize: L.point(size, size, true),
    });
  };

  const fetchDashboardData = useCallback(async () => {
    setIsLoading(true);
    try {
      const [userResponse, uncollectedPrayersResponse] = await Promise.all([
        api.get('/dashboard'),
        api.get('/prayers/uncollected'),
      ]);

      setUserData(userResponse.data);
      const uncollectedPrayersData = uncollectedPrayersResponse.data.prayers.map((prayer) => ({
        ...prayer,
        geo_location: prayer.geo_location ? JSON.parse(prayer.geo_location) : null,
      }));
      setUncollectedPrayers(uncollectedPrayersData);

      const firstPrayerWithLocation = uncollectedPrayersData.find(
        (prayer) =>
          prayer.geo_location &&
          prayer.geo_location.coordinates &&
          prayer.geo_location.coordinates.length >= 2
      );

      if (firstPrayerWithLocation) {
        const location = firstPrayerWithLocation.geo_location;
        setMapCenter([location.coordinates[1], location.coordinates[0]]);
      }

      setDataFetched(true);
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
      if (error.response && error.response.status === 401) {
        logout();
      }
    } finally {
      setIsLoading(false);
    }
  }, [logout]);

  useEffect(() => {
    if (!dataFetched) {
      fetchDashboardData();
    }
  }, [dataFetched, fetchDashboardData]);

  const uncollectedPrayersToDisplay = useMemo(() => {
    return uncollectedPrayers.filter((prayer) => prayer.user_id !== userData?.id);
  }, [uncollectedPrayers, userData]);

  const handleCollectPrayer = useCallback(async () => {
    if (!collectingPrayerId) return;

    try {
      const position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });

      await api.post(`/prayers/${collectingPrayerId}/collect`, {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
        message: wantsToLeaveMessage ? collectMessage : '',
      });

      setSnackbar({ open: true, message: 'Prayer collected successfully!', severity: 'success' });
      setDataFetched(false); // Refresh the data
      setCollectDialogOpen(false);
      setCollectMessage('');
      setCollectingPrayerId(null);
      setWantsToLeaveMessage(false);
    } catch (error) {
      console.error('Error collecting prayer:', error);
      setSnackbar({
        open: true,
        message: 'Failed to collect prayer. Please try again.',
        severity: 'error',
      });
    }
  }, [collectingPrayerId, collectMessage, wantsToLeaveMessage]);

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Box sx={{ position: 'relative', height: 'calc(100vh - 51px)', overflow: 'hidden' }}>
        <MapContainer
          center={mapCenter}
          zoom={mapZoom}
          style={{ height: '100%', width: '100%', zIndex: 1 }}
          zoomControl={false}
          ref={mapRef}
        >
          <ZoomControl position="bottomright" />
          <TileLayer
            attribution="&copy; OpenStreetMap contributors"
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <MarkerClusterGroup chunkedLoading iconCreateFunction={createClusterCustomIcon}>
            {uncollectedPrayersToDisplay.map(
              (prayer) =>
                prayer.geo_location &&
                prayer.geo_location.coordinates &&
                prayer.geo_location.coordinates.length >= 2 && (
                  <Marker
                    key={prayer.id}
                    position={[
                      prayer.geo_location.coordinates[1],
                      prayer.geo_location.coordinates[0],
                    ]}
                    icon={prayerIcon}
                  >
                    <Popup>
                      <StyledPopupContent
                        title={
                          prayer.is_anonymous
                            ? 'Anonymous Prayer'
                            : `Prayer by: ${prayer.username}`
                        }
                        subtitle={prayer.content}
                        caption={`Category: ${prayer.category || 'Uncategorized'}`}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            setCollectingPrayerId(prayer.id);
                            setCollectDialogOpen(true);
                          }}
                          size="small"
                          sx={{
                            alignSelf: 'flex-end',
                            bgcolor: '#2166cd',
                            color: '#fff',
                            borderRadius: '12px',
                            px: 2,
                            py: 0.5,
                            fontSize: '0.875rem',
                            '&:hover': {
                              bgcolor: '#1b58a0',
                            },
                          }}
                        >
                          Collect
                        </Button>
                      </StyledPopupContent>
                    </Popup>
                  </Marker>
                )
            )}
          </MarkerClusterGroup>
        </MapContainer>

        {/* Mobile icons */}
        <Box
          sx={{
            position: 'absolute',
            bottom: 16,
            left: 16,
            zIndex: 3,
            display: { xs: 'flex', sm: 'none' },
            alignItems: 'center',
          }}
        >
          <Fab color="primary" onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
            <MenuIcon />
          </Fab>

          {mobileMenuOpen && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                ml: 1,
                transition: 'opacity 0.3s ease-in-out',
              }}
            >
              {[
                { path: '/prayers/created', icon: <PrayerIcon />, color: '#4CAF50' },
                { path: '/prayers/collected', icon: <CollectionIcon />, color: '#2196F3' },
                { path: '/prayers/add', icon: <AddIcon />, color: '#FF9800' },
              ].map(({ path, icon, color }, index) => (
                <Box key={path} sx={{ ml: index === 0 ? 0 : 0.5 }}>
                  <Fab
                    size="small"
                    sx={{
                      backgroundColor: color,
                      color: '#fff',
                      '&:hover': {
                        backgroundColor: color,
                      },
                    }}
                    onClick={() => navigate(path)}
                  >
                    {icon}
                  </Fab>
                </Box>
              ))}
            </Box>
          )}
        </Box>

        {/* Card container for non-mobile */}
        <Box
          sx={{
            display: { xs: 'none', sm: 'block' },
            position: 'absolute',
            top: 120,
            left: 0,
            right: 0,
            padding: '10px',
            zIndex: 2,
            pointerEvents: 'none',
          }}
        >
          <Container maxWidth="md">
            <Grid
              container
              spacing={1}
              justifyContent="center"
              sx={{
                flexDirection: 'row',
                alignItems: 'flex-start',
              }}
            >
              {[
                { path: '/prayers/created', label: 'Created', icon: <PrayerIcon />, color: '#4CAF50' },
                { path: '/prayers/collected', label: 'Collected', icon: <CollectionIcon />, color: '#2196F3' },
                { path: '/prayers/add', label: 'Add', icon: <AddIcon />, color: '#FF9800' },
              ].map(({ path, label, icon, color }) => (
                <Grid item xs={12} sm={3.8} key={label}>
                  <Card
                    sx={{
                      backgroundColor: 'rgba(255, 255, 255, 0.9)',
                      backdropFilter: 'blur(10px)',
                      boxShadow: 4,
                      borderRadius: 2,
                      borderLeft: `4px solid ${color}`,
                      transition: 'transform 0.2s ease',
                      '&:hover': { transform: 'scale(1.03)' },
                      cursor: 'pointer',
                      pointerEvents: 'auto',
                      height: '80px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    onClick={() => navigate(path)}
                  >
                    <CardContent
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        height: '100%',
                        padding: '8px !important',
                      }}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                        {label !== 'Add' ? (
                          <>
                            <Typography
                              variant="h4"
                              sx={{
                                fontWeight: 'bold',
                                color: color,
                                mr: 1,
                                lineHeight: 1,
                              }}
                            >
                              {label === 'Created'
                                ? userData?.prayers_count
                                : userData?.collected_prayers_count || 0}
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{
                                fontWeight: 'bold',
                                color: 'text.secondary',
                                lineHeight: 1.2,
                              }}
                            >
                              Prayers
                              <br />
                              {label}
                            </Typography>
                          </>
                        ) : (
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: 'bold', color: color, lineHeight: 1.2 }}
                          >
                            Add
                            <br />
                            Prayer
                          </Typography>
                        )}
                        <Box sx={{ color: color, opacity: 0.7, ml: 'auto' }}>
                          {React.cloneElement(icon, { sx: { fontSize: 32 } })}
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>
      </Box>

      {/* Dialog e Snackbar */}
      <Dialog
        open={collectDialogOpen}
        onClose={() => {
          setCollectDialogOpen(false);
          setCollectMessage('');
          setWantsToLeaveMessage(false);
          setCollectingPrayerId(null);
        }}
      >
        <DialogTitle>
          Do you want to leave a message for {userData?.username || 'the author'}?
        </DialogTitle>

        <DialogContent>
          {!wantsToLeaveMessage ? (
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setWantsToLeaveMessage(true)}
                sx={{ mt: 2, mr: 2 }}
              >
                Yes, I want to leave a message
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleCollectPrayer}
                sx={{ mt: 2 }}
              >
                No, collect without a message
              </Button>
            </>
          ) : (
            <>
              <TextField
                label="Your message"
                multiline
                rows={4}
                value={collectMessage}
                onChange={(e) => setCollectMessage(e.target.value)}
                variant="outlined"
                fullWidth
                sx={{ mt: 2 }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleCollectPrayer}
                sx={{ mt: 2 }}
              >
                Submit Message and Collect
              </Button>
            </>
          )}
        </DialogContent>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default Dashboard;
