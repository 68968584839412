import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';


const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const LandingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: ${(props) => (props.darkMode ? '#121212' : '#f0f4f8')};
  color: ${(props) => (props.darkMode ? '#ffffff' : '#333')};
  transition: background-color 0.3s ease, color 0.3s ease;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4rem;
  animation: ${fadeIn} 1s ease-out;
  align-items: center;
  @media (min-width: 768px) {
    padding: 8rem;
  }
`;

const ContentColumn = styled(Column)`
  background-color: ${(props) => (props.darkMode ? '#1e1e1e' : '#ffffff')};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const QuotesColumn = styled(Column)`
  background-color: ${(props) => (props.darkMode ? '#1a3f8f' : '#2166cd')};
  color: #ffffff;
  align-items: center;
`;

const Title = styled.h1`
  font-size: 4rem;
  font-weight: 900;
  font-family: 'Playfair Display', serif;
  color: ${(props) => (props.darkMode ? '#e0e0e0' : '#2166cd')};
  text-align: center;

  @media (min-width: 768px) {
    font-size: 5rem;
  }
`;

const Subtitle = styled.p`
  font-size: 1.1rem;
  max-width: 100%;
  margin-bottom: 2rem;
  line-height: 1.6;
  opacity: 0.85;
  text-align: center;
  color: ${(props) => (props.darkMode ? '#b0b0b0' : '#555')};

  @media (min-width: 768px) {
    font-size: 1.3rem;
    max-width: 500px;
    margin-bottom: 3rem;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: flex-start;
  }
`;

const Button = styled(Link)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.8rem 1.5rem;
  border-radius: 50px;
  text-decoration: none;
  font-weight: bold;
  font-size: 1rem;
  transition: all 0.3s ease;
  text-transform: uppercase;
  width: 100%;
  max-width: 250px;

  @media (min-width: 768px) {
    width: auto;
  }
`;

const CTAButton = styled(Button)`
  background-color: ${(props) => (props.darkMode ? '#90caf9' : '#2166cd')};
  color: #ffffff;
  box-shadow: 0 4px 6px rgba(33, 102, 205, 0.3);

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 8px rgba(33, 102, 205, 0.4);
    background-color: ${(props) => (props.darkMode ? '#1c59b3' : '#1c59b3')};
  }

  &::after {
    content: '';
  }
`;


const SecondaryButton = styled(Button)`
  background-color: transparent;
  color: ${(props) => (props.darkMode ? '#90caf9' : '#2166cd')};
  border: 2px solid ${(props) => (props.darkMode ? '#90caf9' : '#2166cd')};

  &:hover {
    background-color: ${(props) =>
      props.darkMode ? 'rgba(144, 202, 249, 0.1)' : 'rgba(33, 102, 205, 0.1)'};
  }
`;

const QuoteContainer = styled.div`
  text-align: center;
  max-width: 100%;
  padding: 1rem;

  @media (min-width: 768px) {
    max-width: 400px;
    padding: 0;
  }
`;

const QuoteText = styled.p`
  font-size: 1.3rem;
  font-style: italic;
  margin-bottom: 1rem;
  color: rgba(255, 255, 255, 0.9);

  @media (min-width: 768px) {
    font-size: 1.6rem;
  }
`;

const QuoteAuthor = styled.p`
  font-size: 1rem;
  opacity: 0.9;
  color: rgba(255, 255, 255, 0.7);

  @media (min-width: 768px) {
    font-size: 1.1rem;
  }
`;

const ToggleButton = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  cursor: pointer;
  color: ${(props) => (props.darkMode ? '#ffffff' : '#333')};
  font-size: 1.5rem;
  transition: color 0.3s ease;
`;

const inspirationalQuotes = [
  {
    text: "La preghiera è il respiro dell'anima.",
    author: "San Giovanni Crisostomo",
  },
  {
    text: "Pregare significa lasciare vincere Dio.",
    author: "Carlo Carretto",
  },
  // Aggiungi altre citazioni come preferisci...
];

function LandingPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated, loading } = useAuth();
  const [quote, setQuote] = useState({ text: '', author: '' });
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const randomQuote = inspirationalQuotes[Math.floor(Math.random() * inspirationalQuotes.length)];
    setQuote(randomQuote);
  }, []);

  useEffect(() => {
    if (isAuthenticated && !loading && location.pathname !== '/dashboard') {
      navigate('/dashboard');
    }
  }, [isAuthenticated, loading, navigate, location.pathname]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (isAuthenticated) {
    return null;
  }

  return (
    <LandingWrapper darkMode={darkMode}>
      <ToggleButton onClick={() => setDarkMode(!darkMode)}>
		  {darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
		</ToggleButton>
      
      <ContentColumn darkMode={darkMode}>
        <Title darkMode={darkMode}>pray4all</Title>
        <Subtitle darkMode={darkMode}>
          Connect with people around the world through the power of prayer.
          Share your thoughts, support others, and experience the strength of a global spiritual community.
        </Subtitle>
        <ButtonContainer>
          <CTAButton to="/login?tab=register" darkMode={darkMode}>
			  Get Started
			  <ArrowForwardIcon style={{ marginLeft: '0.5rem' }} />
			</CTAButton>

          <SecondaryButton to="/login" darkMode={darkMode}>
            Log In
          </SecondaryButton>
        </ButtonContainer>
      </ContentColumn>
      
      <QuotesColumn darkMode={darkMode}>
        <QuoteContainer>
          <QuoteText>"{quote.text}"</QuoteText>
          <QuoteAuthor>- {quote.author}</QuoteAuthor>
        </QuoteContainer>
      </QuotesColumn>
    </LandingWrapper>
  );
}

export default LandingPage;
