import React, { useState } from 'react';
import { Container, TextField, Button, Snackbar, Alert, FormControlLabel, Checkbox } from '@mui/material';
import api from '../utils/authUtils';

const AddCircleForm = () => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [isPrivate, setIsPrivate] = useState(true);  // Indica se la cerchia è privata
  const [requiresApproval, setRequiresApproval] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post('/circles', {
        name,
        description,
        is_private: isPrivate,
        requires_approval: requiresApproval,
      });
      setSnackbar({ open: true, message: 'Circle created successfully!', severity: 'success' });
      // Resetta il form dopo la creazione
      setName('');
      setDescription('');
      setIsPrivate(true);
      setRequiresApproval(false);
    } catch (error) {
      console.error('Error creating circle:', error);
      setSnackbar({ open: true, message: 'Failed to create circle', severity: 'error' });
    }
  };

  return (
    <Container>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Circle Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          fullWidth
          sx={{ mb: 2 }}
        />
        <TextField
          label="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          fullWidth
          multiline
          rows={4}
          sx={{ mb: 2 }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={isPrivate}
              onChange={(e) => setIsPrivate(e.target.checked)}  // Toggle il valore
            />
          }
          label="Is Private"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={requiresApproval}
              onChange={(e) => setRequiresApproval(e.target.checked)}
            />
          }
          label="Requires Approval"
        />
        <Button type="submit" variant="contained" color="primary">
          Create Circle
        </Button>
      </form>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default AddCircleForm;
