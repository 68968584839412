import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Typography,
  Switch, IconButton, Tooltip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  CircularProgress, Box, Snackbar, Alert, TextField, Pagination
} from '@mui/material';
import { styled } from '@mui/material/styles';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import PersonIcon from '@mui/icons-material/Person';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { useAuth } from '../hooks/useAuth';
import { Container } from '@mui/material';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  '&.MuiTableCell-head': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  '&.MuiTableCell-body': {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function AdminPanel() {
  const [users, setUsers] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState(''); // Stato per la ricerca
  const [currentPage, setCurrentPage] = useState(1); // Stato per la paginazione
  const [usersPerPage] = useState(5); // Numero di utenti per pagina
  const { getUsers, toggleUserActive, toggleUserAdmin, deleteUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const fetchedUsers = await getUsers();
      setUsers(fetchedUsers);
    } catch (error) {
      console.error('Error fetching users:', error);
      setError('Failed to fetch users');
    } finally {
      setLoading(false);
    }
  };

  const handleToggleUserActive = async (userId, currentStatus) => {
    try {
      setLoading(true);
      await toggleUserActive(userId);
      setUsers(users.map(user => 
        user.id === userId ? { ...user, is_active: !currentStatus } : user
      ));
      setSuccessMessage('User active status updated');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error toggling user active status:', error);
      setError('Failed to update user status');
    } finally {
      setLoading(false);
    }
  };

  const handleToggleUserAdmin = async (userId, currentStatus) => {
    try {
      setLoading(true);
      await toggleUserAdmin(userId);
      setUsers(users.map(user => 
        user.id === userId ? { ...user, is_admin: !currentStatus } : user
      ));
      setSuccessMessage('User admin status updated');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error toggling user admin status:', error);
      setError('Failed to update admin status');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteUser = async () => {
    if (selectedUser) {
      try {
        setLoading(true);
        await deleteUser(selectedUser.id);
        setUsers(users.filter(user => user.id !== selectedUser.id));
        setOpenDialog(false);
        setSuccessMessage('User deleted successfully');
        setSnackbarOpen(true);
      } catch (error) {
        console.error('Error deleting user:', error);
        setError('Failed to delete user');
      } finally {
        setLoading(false);
      }
    }
  };

  const openDeleteDialog = (user) => {
    setSelectedUser(user);
    setOpenDialog(true);
  };

  const closeDeleteDialog = () => {
    setOpenDialog(false);
    setSelectedUser(null);
  };

  const closeSnackbar = () => {
    setSnackbarOpen(false);
    setError('');
    setSuccessMessage('');
  };

  // Funzione per gestire la ricerca
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  // Funzione per ottenere gli utenti filtrati
  const filteredUsers = users.filter(user =>
    user.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Funzione per gestire la paginazione
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>User Management</Typography>

      {/* Campo di ricerca con margine superiore per separarlo dall'header */}
      <TextField
        label="Search by username or email"
        variant="outlined"
        fullWidth
        value={searchTerm}
        onChange={handleSearch}
        sx={{ my: 2 }} 
      />

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Username</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell align="center">Admin</StyledTableCell>
              <StyledTableCell align="center">Active</StyledTableCell>
              <StyledTableCell align="center">Email Confirmed</StyledTableCell>
              <StyledTableCell align="center">Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentUsers.map((user) => (
              <StyledTableRow key={user.id}>
                <StyledTableCell component="th" scope="row">{user.username}</StyledTableCell>
                <StyledTableCell>{user.email}</StyledTableCell>
                <StyledTableCell align="center">
                  {user.is_admin ? 
                    <Tooltip title="Admin">
                      <AdminPanelSettingsIcon color="primary" />
                    </Tooltip> : 
                    <Tooltip title="User">
                      <PersonIcon color="action" />
                    </Tooltip>
                  }
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Switch
                    checked={user.is_active}
                    onChange={() => handleToggleUserActive(user.id, user.is_active)}
                    color="primary"
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  {user.confirmed ? 
                    <CheckCircleIcon color="success" /> : 
                    <CancelIcon color="error" />
                  }
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Tooltip title={user.is_admin ? "Remove Admin" : "Make Admin"}>
                    <IconButton onClick={() => handleToggleUserAdmin(user.id, user.is_admin)}>
                      <AdminPanelSettingsIcon color={user.is_admin ? "primary" : "action"} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete User">
                    <IconButton onClick={() => openDeleteDialog(user)}>
                      <DeleteIcon color="error" />
                    </IconButton>
                  </Tooltip>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Paginazione */}
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        <Pagination
          count={Math.ceil(filteredUsers.length / usersPerPage)}
          page={currentPage}
          onChange={handleChangePage}
          color="primary"
        />
      </Box>

      {/* Delete Confirmation Dialog */}
      <Dialog open={openDialog} onClose={closeDeleteDialog}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete user "{selectedUser?.username}"? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog} color="primary">Cancel</Button>
          <Button onClick={handleDeleteUser} color="secondary" disabled={loading}>
            {loading ? <CircularProgress size={20} /> : 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar */}
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={closeSnackbar}>
        <Alert onClose={closeSnackbar} severity={error ? 'error' : 'success'} sx={{ width: '100%' }}>
          {error || successMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default AdminPanel;
