import React, { useState, useEffect, useRef } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  Box,
  Container,
  IconButton,
  useMediaQuery,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import api from '../utils/authUtils';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

import 'leaflet/dist/leaflet.css';

// Definisco StyledPopupContent
function StyledPopupContent({ title, subtitle, caption, children }) {
  return (
    <Box
      sx={{
        p: 1.5,
        bgcolor: '#fff',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        borderRadius: '10px',
        maxWidth: '280px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        border: '1px solid rgba(33, 102, 205, 0.2)',
      }}
    >
      <Typography
        variant="subtitle1"
        sx={{
          fontWeight: 'bold',
          color: '#2166cd',
          mb: 0.1,
          fontFamily: 'Roboto, sans-serif',
        }}
      >
        {title}
      </Typography>

      <Typography
        variant="body2"
        sx={{
          color: '#555',
          fontStyle: 'italic',
          fontFamily: 'Georgia, serif',
          mb: 0.1,
          lineHeight: 1,
        }}
      >
        {subtitle}
      </Typography>

      <Typography
        variant="caption"
        sx={{
          color: '#777',
          fontSize: '12px',
          mb: 1,
        }}
      >
        {caption}
      </Typography>

      {children}
    </Box>
  );
}

function CreatedPrayersPage() {
  const [createdPrayers, setCreatedPrayers] = useState([]);
  const [selectedPrayer, setSelectedPrayer] = useState(null); // Stato per la preghiera selezionata
  const [mapCenter, setMapCenter] = useState([38.1898938, 15.5470017]); // Posizione iniziale della mappa
  const [mapZoom, setMapZoom] = useState(7);  // Zoom iniziale della mappa
  const [markers, setMarkers] = useState([]);  // Stato per i marker della preghiera selezionata
  const mapRef = useRef(null);  // Definisco mapRef

  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const prayerIcon = new L.Icon({
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    shadowSize: [41, 41],
  });

  const collectionIcon = new L.Icon({
    iconUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    shadowSize: [41, 41],
  });

  useEffect(() => {
    const fetchCreatedPrayers = async () => {
      try {
        const response = await api.get('/prayers/collected-details');
        setCreatedPrayers(response.data.prayers_with_collections || []);
      } catch (error) {
        console.error('Error fetching created prayers:', error);
      }
    };

    fetchCreatedPrayers();
  }, []);

  const handlePrayerClick = (prayer) => {
    setSelectedPrayer(prayer);  // Imposta la preghiera selezionata

    const bounds = L.latLngBounds([]);

    // Imposta il marker della preghiera selezionata
    const markersList = [];

    if (prayer.geo_location) {
      try {
        const geoLocation = JSON.parse(prayer.geo_location);
        if (geoLocation.coordinates && geoLocation.coordinates.length === 2) {
          const [lng, lat] = geoLocation.coordinates;
          bounds.extend([lat, lng]);
          markersList.push({
            position: [lat, lng],
            type: 'prayer',
          });
        }
      } catch (error) {
        console.error('Error parsing geolocation:', error);
      }
    }

    // Aggiungi i marker delle collezioni
    prayer.collections.forEach((collection) => {
      if (collection.geo_location) {
        try {
          const geoLocation = JSON.parse(collection.geo_location);
          if (geoLocation.coordinates && geoLocation.coordinates.length === 2) {
            const [lng, lat] = geoLocation.coordinates;
            bounds.extend([lat, lng]);
            markersList.push({
              position: [lat, lng],
              type: 'collection',
              collector_username: collection.collector_username,
              collected_at: collection.collected_at,
              message: collection.message,
            });
          }
        } catch (error) {
          console.error('Error parsing collection geolocation:', error);
        }
      }
    });

    setMarkers(markersList);  // Aggiorna i marker da mostrare sulla mappa

    // Se ci sono bounds validi, zooma sulla zona
    if (bounds.isValid() && mapRef.current) {
      mapRef.current.fitBounds(bounds, { padding: [50, 50] });
    }
  };

  return (
    <Container sx={{ mt: 4, display: 'flex', flexDirection: 'column', height: 'calc(100vh - 64px)' }}>
      <Box display="flex" alignItems="center" mb={2}>
        <IconButton onClick={() => navigate('/dashboard')}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h5" component="h1" ml={1}>
          Map of Created Prayers
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          flex: 1,
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            width: isMobile ? '100%' : '300px',
            borderRight: isMobile ? 'none' : '1px solid #e0e0e0',
            borderBottom: isMobile ? '1px solid #e0e0e0' : 'none',
            overflowY: 'auto',
            padding: '16px',
            backgroundColor: '#f5f7fa',
          }}
        >
          <List>
            {createdPrayers.map((prayer) => (
              <ListItem
                key={prayer.id}
                button
                onClick={() => handlePrayerClick(prayer)}
                sx={{
                  borderRadius: '4px',
                  backgroundColor: 'white',
                  boxShadow: '0 1px 2px rgba(0,0,0,0.1)',
                  padding: '12px 16px',
                  '&:hover': {
                    backgroundColor: '#f8f9fa',
                  },
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  mb: 2,
                }}
              >
                <ListItemText
                  primary={
                    <Typography variant="subtitle1" sx={{ fontWeight: 500, color: '#202124' }}>
                      {prayer.content}
                    </Typography>
                  }
                  secondary={
                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 0.5 }}>
                      <FavoriteIcon sx={{ fontSize: 14, color: 'error.main', mr: 0.5 }} />
                      <Typography variant="body2" sx={{ color: 'text.secondary', mr: 1 }}>
                        {prayer.collections.length}
                      </Typography>
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {prayer.category}
                      </Typography>
                    </Box>
                  }
                />
                {prayer.collections.map((collection) => (
                  <Box key={collection.collection_id} sx={{ mt: 1, pl: 2 }}>
                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                      Collected by: {collection.collector_username}
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <ChatBubbleOutlineIcon sx={{ fontSize: 16, mr: 1 }} />
                      <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
                        {collection.message || 'No message left'}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </ListItem>
            ))}
          </List>
        </Box>

        <Box sx={{ flex: 1, position: 'relative' }}>
          <MapContainer
            center={mapCenter}
            zoom={mapZoom}
            style={{ height: '100%', width: '100%' }}
            ref={mapRef}
          >
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

            {/* Renderizza i marker */}
            {markers.map((marker, index) => (
              <Marker
                key={`marker-${index}`}
                position={marker.position}
                icon={marker.type === 'prayer' ? prayerIcon : collectionIcon}
              >
                <Popup>
                  {marker.type === 'prayer' ? (
                    <StyledPopupContent
                      title={selectedPrayer.content}
                      subtitle={`Category: ${selectedPrayer.category}`}
                      caption={`Created at: ${new Date(selectedPrayer.created_at).toLocaleString()}`}
                    />
                  ) : (
                    <StyledPopupContent
                      title={`Collected by: ${marker.collector_username}`}
                      subtitle={`Collected at: ${new Date(marker.collected_at).toLocaleString()}`}
                      caption={marker.message || 'No message left'}
                    />
                  )}
                </Popup>
              </Marker>
            ))}
          </MapContainer>
        </Box>
      </Box>
    </Container>
  );
}

export default CreatedPrayersPage;
