import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { 
  TextField, 
  Button, 
  Container, 
  Typography, 
  Box, 
  Card, 
  CardContent, 
  Avatar, 
  CircularProgress, 
  Tabs, 
  Tab,
  Alert
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useAuth } from '../hooks/useAuth';
import GoogleIcon from '@mui/icons-material/Google';

function AuthForm({ isUnconfirmed }) {
  const location = useLocation();
  const navigate = useNavigate();
  const { login, register, resendConfirmationEmail, isAuthenticated, loading } = useAuth();
  
  const [activeTab, setActiveTab] = useState(0);
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    first_name: '',
    last_name: ''
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showResendOption, setShowResendOption] = useState(false);
  
useEffect(() => {
  // Check if there's an error or message in the query parameters
  const params = new URLSearchParams(location.search);
  const queryError = params.get('error');
  const queryMessage = params.get('message');

  if (queryError === 'account_deactivated') {
    setError('This account has been deactivated by an administrator. Please contact support.');
  } else if (queryError === 'internal_server_error') {
    setError('An internal server error occurred. Please try again later.');
  }

  // Modifica qui per gestire l'attivazione dell'account creato tramite OAuth
  if (queryMessage === 'account_needs_admin_activation') {
    setSuccess('Your account has been confirmed. Please wait for an administrator to activate your account.');
  } else if (queryMessage === 'account_created_needs_activation') {
    setSuccess('Your account has been created. Please wait for an administrator to activate your account.');
  }

  if (isUnconfirmed) {
    setError('Please confirm your email before logging in.');
    setShowResendOption(true);
  }
}, [location.search, isUnconfirmed]);




  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tab = params.get('tab');
    setActiveTab(tab === 'register' ? 1 : 0);
  }, [location.search]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');
    setSuccess('');
    setShowResendOption(false);

    try {
        if (activeTab === 0) { // Login
            const response = await login({ 
                email: formData.email, 
                password: formData.password 
            });
            
            if (response.is_active === false) {
                setError('This account has been deactivated. Please contact support.');
                setIsLoading(false);
                return;
            }
        } else { // Register
            await register(formData);
            setSuccess('Registration successful. Please check your email to confirm your account.');
            setActiveTab(0);
            setFormData(prevData => ({
                ...prevData,
                password: ''
            }));
        }
    } catch (err) {
        console.error('Error during authentication:', err);
        if (err.response && err.response.data) {
            const { error_code, message } = err.response.data;
            if (error_code === 'email_not_confirmed') {
                setError(message || 'Please confirm your email before logging in.');
                setShowResendOption(true);
            } else if (error_code === 'account_deactivated') {
                setError('This account has been deactivated. Please contact support.');
            } else {
                setError(message || 'An error occurred. Please try again.');
            }
        } else {
            setError(err.message || 'An unexpected error occurred. Please try again.');
        }
    } finally {
        setIsLoading(false);
    }
};


  const handleResendConfirmation = async () => {
    try {
      await resendConfirmationEmail(formData.email);
      setSuccess('Confirmation email resent. Please check your inbox.');
      setShowResendOption(false);
    } catch (err) {
      console.error('Error resending confirmation email:', err);
      setError('Failed to resend confirmation email. Please try again.');
    }
  };

const handleOAuth = async (provider) => {
  try {
    if (provider === 'google') {
      window.location.href = `${process.env.REACT_APP_API_URL}/api/login/google`;
    } else {
      window.location.href = `/api/oauth/${provider}`;
    }
  } catch (err) {
    console.error('Error during OAuth authentication:', err);
    if (err.response && err.response.data) {
      const { error_code, message } = err.response.data;
      if (error_code === 'account_deactivated') {
        setError('This account has been deactivated. Please contact support.');
      } else {
        setError(message || 'An error occurred. Please try again.');
      }
    } else {
      setError('An unexpected error occurred. Please try again.');
    }
  }
};


  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setError('');
    setSuccess('');
    setShowResendOption(false);
    setFormData({
      username: '',
      email: '',
      password: '',
      first_name: '',
      last_name: ''
    });
    navigate(`/login?tab=${newValue === 0 ? 'login' : 'register'}`, { replace: true });
  };


  return (
    <Box
      sx={{
        minHeight: '100vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#2166cd', // Set the entire background to blue
      }}
    >
      <Container
        component="main"
        maxWidth="xs"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: '#ffffff' }}>
          <LockOutlinedIcon style={{ color: '#2166cd' }} />
        </Avatar>
        <Typography component="h1" variant="h5" sx={{ color: '#ffffff' }}>
          {activeTab === 0 ? 'Welcome Back 👋' : 'Register'}
        </Typography>
        <Card
          sx={{
            mt: 3,
            width: '100%',
            boxShadow: 3,
            backgroundColor: '#ffffff',
            color: '#2166cd',
          }}
        >
          <CardContent>
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              centered
              sx={{ color: '#2166cd' }}
            >
              <Tab label="Login" />
              <Tab label="Register" />
            </Tabs>
            {error && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {error}
              </Alert>
            )}
            {showResendOption && (
              <Box sx={{ mt: 2, textAlign: 'center' }}>
                <Typography variant="body2">
                  Didn't receive the confirmation email?{' '}
                  <Button color="primary" onClick={handleResendConfirmation}>
                    Resend
                  </Button>
                </Typography>
              </Box>
            )}
            {success && (
              <Alert severity="success" sx={{ mt: 2 }}>
                {success}
              </Alert>
            )}
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
              {activeTab === 1 && (
                <>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Username"
                    name="username"
                    value={formData.username}
                    onChange={handleInputChange}
                    disabled={isLoading}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="First Name"
                    name="first_name"
                    value={formData.first_name}
                    onChange={handleInputChange}
                    disabled={isLoading}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="Last Name"
                    name="last_name"
                    value={formData.last_name}
                    onChange={handleInputChange}
                    disabled={isLoading}
                  />
                </>
              )}
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Email"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleInputChange}
                disabled={isLoading}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Password"
                name="password"
                type="password"
                value={formData.password}
                onChange={handleInputChange}
                disabled={isLoading}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  backgroundColor: '#1b58a0', // Dark blue background for buttons
                  color: '#ffffff', // White text for buttons
                  '&:hover': {
                    backgroundColor: '#184d8a',
                  },
                }}
                disabled={isLoading}
              >
                {isLoading ? (
                  <CircularProgress size={24} />
                ) : activeTab === 0 ? (
                  'Login'
                ) : (
                  'Register'
                )}
              </Button>

              <Button
                fullWidth
                variant="outlined"
                startIcon={<GoogleIcon />}
                sx={{
                  mt: 1,
                  color: '#ffffff',
                  backgroundColor: '#1b58a0',
                  borderColor: '#1b58a0',
                  '&:hover': {
                    backgroundColor: '#184d8a',
                  },
                }}
                onClick={() => handleOAuth('google')}
              >
                {activeTab === 0 ? 'Sign in with Google' : 'Sign up with Google'}
              </Button>

              <Button
                fullWidth
                variant="contained"
                sx={{
                  mt: 1,
                  backgroundColor: '#ffffff',
                  color: '#1b58a0',
                  '&:hover': {
                    backgroundColor: '#f0f0f0',
                  },
                }}
                onClick={() => handleOAuth('facebook')}
              >
                {activeTab === 0 ? 'Login with Facebook' : 'Register with Facebook'}
              </Button>
            </Box>
            </CardContent>
        </Card>
      </Container>
    </Box>
  );
}

export default AuthForm;
