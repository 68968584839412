import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import {
  IconButton, Drawer, List, ListItem, ListItemText, Avatar, 
  Menu, MenuItem, ListItemIcon, Badge, Tabs, Tab, Box, useMediaQuery,
  CircularProgress, Typography, Dialog, DialogTitle, DialogContent, Button
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import PrayerIcon from '@mui/icons-material/AccessibilityNew';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import GroupIcon from '@mui/icons-material/Group'; // Aggiunto per le cerchie
import CloseIcon from '@mui/icons-material/Close';
import { useAuth } from '../hooks/useAuth';
import api from '../utils/authUtils';

// Importa il logo
import logo from '../logo.png';  // Aggiorna con il percorso corretto

// Styled components
const HeaderWrapper = styled.header`
  background-color: #2166cd;
  padding: 1rem 2rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #1b58a0;
  }
`;

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const LogoImage = styled.img`
  height: 40px;
  margin-right: 10px;
`;

const LogoText = styled(Link)`
  font-size: 2rem;
  font-weight: bold;
  color: #ffffff;
  text-decoration: none;
  letter-spacing: 1px;

  &:hover {
    color: #ffffff;
    text-decoration: underline;
  }
`;

const StyledNavLink = styled(Link)`
  display: flex;
  align-items: center;
  color: #ffffff;
  text-decoration: none;
  font-weight: 500;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  font-size: 1rem;

  &:hover {
    background-color: #184d8a;
    transition: background-color 0.3s ease;
  }

  &:focus {
    outline: 2px solid #ffffff;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  &:hover {
    background-color: #2166cd !important;
    color: #ffffff;
  }
`;

const NavLinks = styled.nav`
  display: flex;
  gap: 2rem;
  align-items: center;
`;

const NotificationContainer = styled.div`
  padding: 10px;
  max-height: 300px;
  overflow-y: auto;
`;

function Header() {
  const navigate = useNavigate();
  const { isAuthenticated, logout, user } = useAuth();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [userData, setUserData] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [unreadNotifications, setUnreadNotifications] = useState([]);
  const [readNotifications, setReadNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [notificationAnchorEl, setNotificationAnchorEl] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [notificationDialogOpen, setNotificationDialogOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width:768px)');
  const [isLoading, setIsLoading] = useState(true);

  const fetchUserData = useCallback(async () => {
    try {
      const response = await api.get('/profile');
      setUserData(response.data);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  }, []);

  const fetchNotifications = useCallback(async () => {
    try {
      const response = await api.get('/notifications');
      const allNotifications = response.data.notifications;
      const unread = allNotifications.filter(n => !n.read);
      const read = allNotifications.filter(n => n.read);
      setNotifications(allNotifications);
      setUnreadNotifications(unread);
      setReadNotifications(read);
      setUnreadCount(unread.length);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  }, []);

  useEffect(() => {
    setIsLoading(true);
    if (isAuthenticated) {
      Promise.all([fetchUserData(), fetchNotifications()])
        .then(() => setIsLoading(false))
        .catch(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
  }, [isAuthenticated, fetchUserData, fetchNotifications]);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleNotificationMenuOpen = (event) => {
    setNotificationAnchorEl(event.currentTarget);
  };

  const handleNotificationMenuClose = () => {
    setNotificationAnchorEl(null);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleLogout = async () => {
    handleMenuClose();
    await logout();
    navigate('/');
    window.location.reload();
  };

	const handleNotificationClick = async (notification) => {
	  try {
		await api.post(`/notifications/${notification.id}/mark-read`);
		setUnreadNotifications(prev => prev.filter(n => n.id !== notification.id));
		setReadNotifications(prev => [...prev, { ...notification, read: true }]);
		setUnreadCount(prev => Math.max(prev - 1, 0));
	  } catch (error) {
		console.error('Error marking notification as read:', error);
	  }
	};

  const openNotificationsDialog = () => {
    setNotificationDialogOpen(true);
  };

  const closeNotificationsDialog = () => {
    setNotificationDialogOpen(false);
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <HeaderWrapper>
      <HeaderContent>
        <LogoContainer>
          <LogoImage src={logo} alt="pray4all logo" />
          <LogoText to={isAuthenticated ? "/dashboard" : "/"}>pray4all</LogoText>
        </LogoContainer>

        {isMobile ? (
          <>
            <IconButton onClick={handleDrawerToggle} sx={{ color: '#ffffff' }}>
              <MenuIcon />
            </IconButton>
            <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerToggle}>
              <List>
                {isAuthenticated ? (
                  <>
                    <ListItem button component={Link} to="/dashboard" onClick={handleDrawerToggle}>
                      <ListItemIcon>
                        <PrayerIcon />
                      </ListItemIcon>
                      <ListItemText primary="Prayers Dashboard" />
                    </ListItem>
                    {user?.is_admin && (
                      <ListItem button component={Link} to="/admin" onClick={handleDrawerToggle}>
                        <ListItemIcon>
                          <AdminPanelSettingsIcon />
                        </ListItemIcon>
                        <ListItemText primary="Admin Panel" />
                      </ListItem>
                    )}
                    <ListItem button component={Link} to="/profile" onClick={handleDrawerToggle}>
                      <ListItemIcon>
                        <AccountCircleIcon />
                      </ListItemIcon>
                      <ListItemText primary="My Profile" />
                    </ListItem>

                    {/* Cerchie di Preghiera */}
                    <ListItem button component={Link} to="/circles" onClick={handleDrawerToggle}>
                      <ListItemIcon>
                        <GroupIcon />
                      </ListItemIcon>
                      <ListItemText primary="Cerchie di Preghiera" />
                    </ListItem>

                    <ListItem button onClick={openNotificationsDialog}>
                      <ListItemIcon>
                        <Badge badgeContent={unreadCount} color="error">
                          <NotificationsIcon style={{ color: '#1b58a0' }} />
                        </Badge>
                      </ListItemIcon>
                      <ListItemText primary="Notifications" />
                    </ListItem>
                    
                    <ListItem button onClick={() => {
                      handleLogout();
                      handleDrawerToggle();
                    }}>
                      <ListItemIcon>
                        <ExitToAppIcon />
                      </ListItemIcon>
                      <ListItemText primary="Logout" />
                    </ListItem>
                  </>
                ) : (
                  <ListItem button component={Link} to="/login" onClick={handleDrawerToggle}>
                    <ListItemText primary="Login" />
                  </ListItem>
                )}
              </List>
            </Drawer>

            <Dialog fullScreen open={notificationDialogOpen} onClose={closeNotificationsDialog}>
              <DialogTitle>
                Notifications
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={closeNotificationsDialog}
                  aria-label="close"
                  sx={{ position: 'absolute', right: 8, top: 8 }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <Tabs value={tabValue} onChange={handleTabChange} centered>
                  <Tab label={`Unread (${unreadNotifications.length})`} />
                  <Tab label={`Read (${readNotifications.length})`} />
                </Tabs>

                <NotificationContainer>
                  {tabValue === 0 && (
                    unreadNotifications.length > 0 ? (
                      unreadNotifications.map(notification => {
                        const extraData = typeof notification.extra_data === 'string' ? JSON.parse(notification.extra_data) : notification.extra_data || {};
                        return (
                          <MenuItem key={notification.id} onClick={() => handleNotificationClick(notification)}>
                            <ListItemIcon>
                              <PrayerIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText 
                              primary={`Collected by ${extraData.collector_username ? extraData.collector_username : 'Anonymous'}`}
                              secondary={`Collected on: ${new Date(notification.created_at).toLocaleString()}`}
                            />
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem>No unread notifications</MenuItem>
                    )
                  )}

                  {tabValue === 1 && (
  readNotifications.length > 0 ? (
    readNotifications.map(notification => {
      const extraData = typeof notification.extra_data === 'string' ? JSON.parse(notification.extra_data) : notification.extra_data;
      
      return (
        <MenuItem key={notification.id}>
          <ListItemIcon>
            <PrayerIcon color="disabled" />
          </ListItemIcon>
          <ListItemText 
            primary={`Collected by ${extraData.collector_username ? extraData.collector_username : 'Anonymous'}`}
            secondary={`Collected on: ${new Date(notification.created_at).toLocaleString()}`}
          />
        </MenuItem>
      );
    })
  ) : (
    <MenuItem>No read notifications</MenuItem>
  )
)}


                    ) : (
                      <MenuItem>No read notifications</MenuItem>
                    )
                  )}
                </NotificationContainer>
              </DialogContent>
            </Dialog>
          </>
        ) : (
          <NavLinks>
            {isAuthenticated ? (
              <>
                <StyledNavLink to="/dashboard">
                  <PrayerIcon sx={{ mr: 1 }} />
                  Prayers Dashboard
                </StyledNavLink>
                {user?.is_admin && (
                  <StyledNavLink to="/admin">
                    <AdminPanelSettingsIcon sx={{ mr: 1 }} />
                    Admin Panel
                  </StyledNavLink>
                )}
                <StyledNavLink to="/profile">
                  <AccountCircleIcon sx={{ mr: 1 }} />
                  My Profile
                </StyledNavLink>

                {/* Cerchie di Preghiera */}
                <StyledNavLink to="/circles">
                  <GroupIcon sx={{ mr: 1 }} />
                  Cerchie di Preghiera
                </StyledNavLink>

                <IconButton color="inherit" onClick={handleNotificationMenuOpen}>
                  <Badge badgeContent={unreadCount} color="error">
                    <NotificationsIcon style={{ color: '#ffffff' }} />
                  </Badge>
                </IconButton>

                <Menu
                  anchorEl={notificationAnchorEl}
                  open={Boolean(notificationAnchorEl)}
                  onClose={handleNotificationMenuClose}
                  PaperProps={{
                    style: {
                      maxHeight: '400px',
                      width: '400px',
                    },
                  }}
                >
                  <Tabs value={tabValue} onChange={handleTabChange} centered>
                    <Tab label={`Unread (${unreadNotifications.length})`} />
                    <Tab label={`Read (${readNotifications.length})`} />
                  </Tabs>

                  
					<NotificationContainer>
					 {tabValue === 0 && (
  unreadNotifications.length > 0 ? (
    unreadNotifications.map(notification => {
      const extraData = typeof notification.extra_data === 'string' ? JSON.parse(notification.extra_data) : notification.extra_data || {};
      return (
        <MenuItem key={notification.id} onClick={() => handleNotificationClick(notification)}>
          <ListItemIcon>
            <PrayerIcon color="primary" />
          </ListItemIcon>
          <ListItemText
            primary={`Collected by ${extraData.collector_username ? extraData.collector_username : 'Anonymous'}`}
            secondary={`Collected on: ${new Date(notification.created_at).toLocaleString()}`}
          />
        </MenuItem>
      );
    })
  ) : (
    <MenuItem>No unread notifications</MenuItem>
  )
)}


					  {tabValue === 1 && (
						readNotifications.length > 0 ? (
						  readNotifications.map(notification => {
                            const extraData = typeof notification.extra_data === 'string' ? JSON.parse(notification.extra_data) : notification.extra_data || {};
                            return (
							<MenuItem key={notification.id}>
							  <ListItemIcon>
								<PrayerIcon color="disabled" />
							  </ListItemIcon>
							  <ListItemText 
								primary={`Collected by ${extraData.collector_username ? extraData.collector_username : 'Anonymous'}`}
								secondary={`Collected on: ${new Date(notification.created_at).toLocaleString()}`}
							  />
							</MenuItem>
                            );
                          })
						) : (
						  <MenuItem>No read notifications</MenuItem>
						)
					  )}
					</NotificationContainer>

                </Menu>

                <IconButton color="inherit" onClick={handleMenuOpen}>
                  <Avatar
                    src={userData?.profile_picture_url || ''}
                    alt={userData?.username}
                    sx={{ bgcolor: "#ffffff", color: "#1b58a0" }}
                  >
                    {!userData?.profile_picture_url && userData?.username?.[0].toUpperCase()}
                  </Avatar>
                </IconButton>

                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <StyledMenuItem onClick={handleLogout}>
                    <ListItemIcon>
                      <ExitToAppIcon />
                    </ListItemIcon>
                    Logout
                  </StyledMenuItem>
                </Menu>
              </>
            ) : (
              <StyledNavLink to="/login">Login</StyledNavLink>
            )}
          </NavLinks>
        )}
      </HeaderContent>
    </HeaderWrapper>
  );
}

export default Header;
