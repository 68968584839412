import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Typography, Container, CircularProgress, Box, Button, Alert } from '@mui/material';

function EmailConfirmation() {
  const [status, setStatus] = useState('loading'); // Stato della conferma
  const location = useLocation(); // Per ottenere il token dall'URL
  const navigate = useNavigate(); // Per reindirizzare alla pagina di login dopo la conferma

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');

    if (!token) {
      setStatus('error');
      return;
    }

    const apiUrl = `${process.env.REACT_APP_API_URL}/api/confirm`;
    console.log(`Sending confirmation request to: ${apiUrl}`);

    fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token: token })
    })
      .then(response => {
        console.log('Response status:', response.status);
        return response.json();
      })
      .then(data => {
        console.log('Response data:', data);
        if (data.message === "Email confirmed successfully!" || data.message === "Email already confirmed.") {
          setStatus('success');
        } else {
          setStatus('error');
        }
      })
      .catch(error => {
        console.error('Error:', error);
        setStatus('error');
      });
  }, [location]);

  return (
    <Container maxWidth="sm" sx={{ mt: 8 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Email Confirmation
        </Typography>
        {/* Aggiungi un margine in alto per abbassare il messaggio */}
        <Box sx={{ mt: 4 }}> 
          {status === 'loading' && <CircularProgress />}
          {status === 'success' && (
            <>
              <Alert severity="success">Your email has been successfully confirmed!</Alert>
              <Button variant="contained" onClick={() => navigate('/login')} sx={{ mt: 2 }}>
                Go to Login
              </Button>
            </>
          )}
          {status === 'error' && (
            <Alert severity="error">
              There was an error confirming your email. Please try again or contact support.
            </Alert>
          )}
        </Box>
      </Box>
    </Container>
  );
}

export default EmailConfirmation;
